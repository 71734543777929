<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignOrderForm"
        ref="assignOrderForm"
        class="custom-form pt-2"
        @submit.prevent="assignOrder"
      >
        <v-row>
          <v-col
            v-for="param in containerInfoParams"
            :key="param.text"
            :cols="param.cols"
            class="pt-1 pb-0 pr-0 text-14"
          >
            <span class="input-label mr-1">{{ param.text }}: </span>
            <span class="chosen-client-details">{{ param.value || '-' }}</span>
          </v-col>
          <v-col
            cols="12"
            class="pt-8"
          >
            <v-autocomplete
              outlined
              :items="containers"
              label="Kontener zlecenia"
              v-model="containerRefNumber"
              :item-text="(item) => `Kontener: ${item.refNumberWithType}, zlecenie: ${item.orderRefNumber}`"
              item-value="refNumber"
              :rules="[rules.required]"
              placeholder="Wybierz kontener"
              append-icon="mdi-chevron-down"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!containerRefNumber"
        type="submit"
        form="assignOrderForm"
      >
        Przypisz zlecenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import api from '../../api/v1'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      containerRefNumber: null,
      containers: [],
      rules
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.course.isProcessing,
      item: state => state.layout.dialog.item,
      department: state => state.core.department
    }),
    course () {
      return this.item.swapCourse ? this.item.swapCourse : this.item
    },
    containerInfoParams () {
      const { containerType, client, address } = this.course
      return [
        { text: 'Nazwa klienta', value: client && client.name, cols: 12 },
        { text: 'Adres lokalizacji', value: address && address.formattedAddress, cols: 12 },
        { text: 'Zadeklarowany typ kontenera', value: containerType && containerType.name, cols: 12 }
      ]
    }
  },
  mounted () {
    api.searchAddresses({
      departmentId: this.department.id,
      addressId: this.course.address.id
    }).then((resp) => {
      this.containers = resp.data[0] ? resp.data[0].containers : []
    })
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      assignOrder (dispatch) {
        const payload = {
          id: this.course.id,
          params: {
            containerRefNumber: this.containerRefNumber
          }
        }
        return dispatch('course/assignOrder', payload)
          .then(() => {
            this.closeDialog()
          })
      }
    })
  }
}
</script>
